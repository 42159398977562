<template> <!-- 특수화물 선적문의 >> 위험물 -->
  <div class="content">
    <!-- content : 화면 리사이징 되는 화면 resize 추가 -->
    <e-breadcrumbs />
    <div id="layerPop">
      <win-layer-pop>
        <component
          v-if="customComponent"
          :is="customComponent"
          :detail="detail"
          @close="closePoup"
        />
      </win-layer-pop>
    </div>

    <h1 class="page_title">{{ $t('menu.MENU04.010') }}</h1>
    <form id="frm">
      <div class="content_box pb15">
        <!-- content_box -->
        <table class="tbl_search">
          <colgroup>
            <col width="70px">
            <col width="152px">
            <col width="*">
            <col width="100px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.SPEC030.043') }}</th>
              <td class="pr0">
                <select class="wid150" v-model="param.asDtKnd" @change="optionChange()">
                  <option value="RQ">Request No</option>
                  <option value="DT">Request Date</option>
                </select>
              </td>
              <td colspan="4">
                <div v-if="param.asDtKnd === 'RQ'">
                  <!-- Request No 선택일 경우 -->
                  <input class="wid150" type="text" v-model.trim="param.asReqNo1">
                </div><!-- Request No 선택일 경우 // -->
                <div v-if="param.asDtKnd === 'DT'">
                  <e-date-range-picker
                    :id="'fromDtToDt'"
                    @change="changeDateRange"
                    :sdate="dateFrom"
                    :edate="dateTo"
                  />
                </div>
              </td>
              <td class="text_right">
                <a class="button blue sh" @click="searchData()">{{ $t('msg.CMBA100.00120') }}</a>
              </td>
            </tr>
            <tr style="height: 15px;" v-show="validationDt">
              <td>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.SPEC030.006') }}</th>
              <td colspan="2">
                <e-auto-complete-place id="polPol"
                                       @change="changePol"
                                       :ctr-cd="pol.ctrCd"
                                       :plc-cd="pol.plcCd"
                                       :is-char="true"
                                       :is-char-alert="true"
                />
              </td>
              <th><span class="ml20">{{ $t('msg.SPEC030.007') }}</span></th>
              <td colspan="2">
                <e-auto-complete-place
                  id="podPod" @change="changePod"
                  :ctr-cd="pod.ctrCd"
                  :plc-cd="pod.plcCd"
                  :is-char="true"
                  :is-char-alert="true"
                />
              </td>
            </tr>
            <!-- 220407 체크박스 별도 라인으로 수정 -->
            <tr>
              <td class="pl0" colspan="6">
                <div>
                  <span class="ml10">
                    <input type="checkbox" id="ch1" name="chk1" v-model="param.apvStsCd" value="02">
                    <label for="ch1"><span></span>{{ $t('msg.SPEC030.002') }}</label>
                    <input type="checkbox" id="ch2" name="chk2" v-model="param.apvStsCd" value="03">
                    <label for="ch2"><span></span>{{ $t('msg.SPEC030.003') }}</label>
                    <input type="checkbox" id="ch3" name="chk3" v-model="param.apvStsCd" value="01,04">
                    <label for="ch3"><span></span>{{ $t('msg.SPEC030.004') }}</label>
                  </span>
                </div>
              </td>
            </tr>
          <!--//220407 체크박스 별도 라인으로 수정 -->
          </tbody>
        </table>
      </div><!-- content_box // -->
    </form>

    <div class="content_box mt10">
      <!-- content_box -->
      <div class="flex_box">
        <span class="tot_num">Total : <span class="num">{{ gridList.length }}</span></span>
        <span class="input_box">
          <input class="wid300"
                 type="text"
                 v-model="templateStr"
                 :placeholder="$t('msg.SPEC010G010.000')"
                 @keyup="templateEnter"
          >
        </span>
        <span class="ml_auto cal_btn"><a class="button sm" href="#" @click.prevent="exlDownload()">{{ $t('msg.MYIN020T010.016') }}</a></span> <!-- 엑셀 다운로드 -->
      </div>

      <div id="realgrid" style="width: 100%; height: 450px" />
      <!-- <div
        v-if="gridList.length > 0"
        class="paging"
      >
        <a
          v-if="isPaging1"
          class="btn prev"
          @click.prevent="setPrevPage()"
        >Prev</a>
        <a>{{ currentPage + 1 }}/{{ totalPage }}</a>
        <a
          class="btn next"
          @click.prevent="setNextPage()"
        >Next</a>
      </div> -->
    </div><!-- content_box // -->
    <div class="flex_box mt10">
      <a class="button lg" @click.prevent="openPopup('DangerQstDtlPop')">{{ $t('msg.SPEC030.045') }}</a>
      <a class="button blue lg ml_auto mr5" @click.prevent="openPopup('UnnoSearchPop')">{{ $t('common.UNNO_SEARCH.001') }}</a>
      <a class="button blue lg mr5" @click.prevent="openPopup('DangerShipQstPop')">{{ $t('msg.SPEC030.046') }}</a>
    </div>
    <br />
    <div v-show="memberDetail.userCtrCd === 'KR'" class="desc_list" style="margin-top:20px;">
      <ul>
        <li class="font_medium">[ 주의사항 ]</li>
        <li>
          위험물 분류는 아래 IMDG Code 규정에 따라 화주측 또는 IMDG Code에서 정한 주무관청이 행하여야하며,선사에서는 화주측에서 제공해주신 Class/ UNNO/ Packing 등의 정보를 바탕으로 당사 선박에 선적 가능 여부를 판단합니다.
          아래 규정에 따라 실화주측 또는 IMDG Code에서 정한 주무 관청 (대한민국 : 한국해사위험물 검사원)을 통하여 안내받으신 대로 진행하시기 바랍니다.
        </li> <br />
        <li>※ 한국해사 위험물 검사원</li>
        <li> 1) Web site : http://www.komdi.or.kr/</li>
        <li>- Web ->고객지원 -> Q&A 게시판을 통한 위험물 여부 문의 가능.</li> <br />

        <li>&lt;참고&gt;</li>
        <li>IMDG Code 2장 (책임 ) Responsibilities</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import JSZip from 'jszip'
import dangerShipList from '@/api/rest/trans/dangerShipList'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import { rootComputed } from '@/store/helpers'
import moment from 'moment'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'reqNo', dataType: 'text' },
  { fieldName: 'fstEntDt', dataType: 'text' },
  { fieldName: 'polPortCd', dataType: 'text' },
  { fieldName: 'podPortCd', dataType: 'text' },
  { fieldName: 'polPortNm', dataType: 'text' },
  { fieldName: 'podPortNm', dataType: 'text' },
  { fieldName: 'polPortCtrCd', dataType: 'text' },
  { fieldName: 'podPortCtrCd', dataType: 'text' },
  { fieldName: 'cntrTypCd', dataType: 'text' },
  { fieldName: 'imdunNo1', dataType: 'text' },
  { fieldName: 'imoClasCd1', dataType: 'text' },
  { fieldName: 'imdunNo2', dataType: 'text' },
  { fieldName: 'imoClasCd2', dataType: 'text' },
  { fieldName: 'apvStsCd', dataType: 'text' }
]

export const columns = [
  //checkbox reqNo
  {
    name: 'reqNo',
    fieldName: 'reqNo',
    type: 'text',
    header: { text: 'REQ No.' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        let str = ''
        str = '<a style="text-decoration:underline; font-weight: bold;">' + data + '</a>'
        return str
      }
    }
  },
  {
    name: 'fstEntDt',
    fieldName: 'fstEntDt',
    type: 'text',
    header: { text: 'Date' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
          let data = cell.value
          let str = ''
          data = data.substring(0, 4) + '.' + data.substring(4, 6) + '.' + data.substring(6, 8)
          str = '<span>' + data + '</span>'
          return str
      }
    }
  },
  // { name: 'polPortCd', fieldName: 'polPortCd', type: 'text', header: { text: 'POL' }, editable: false },
  // { name: 'podPortCd', fieldName: 'podPortCd', type: 'text', header: { text: 'POD' }, editable: false },
  {
    name: 'polPortNm',
    fieldName: 'polPortNm',
    type: 'text',
    header: { text: 'POL' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        const idx = cell.index.dataRow
        const polPortCtrCd = grid.getValue(idx, 'polPortCtrCd')

        // 출발, 도착 name 수정
        let polPortNm = data
        polPortNm = data.split(',')
        polPortNm = polPortNm[0] + ',' + polPortCtrCd

        let str = ''
        str = polPortNm
        return str
      }
    }
  },
  {
    name: 'podPortNm',
    fieldName: 'podPortNm',
    type: 'text',
    header: { text: 'POD' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        const idx = cell.index.dataRow
        const podPortCtrCd = grid.getValue(idx, 'podPortCtrCd')

        // 출발, 도착 name 수정
        let podPortNm = data
        podPortNm = data.split(',')
        podPortNm = podPortNm[0] + ',' + podPortCtrCd

        let str = ''
        str = podPortNm

        return str
      }
    }
  },
  { name: 'polPortCtrCd', fieldName: 'polPortCtrCd', type: 'text', header: { text: 'TYPE' }, visible: false },
  { name: 'podPortCtrCd', fieldName: 'podPortCtrCd', type: 'text', header: { text: 'TYPE' }, visible: false },
  { name: 'cntrTypCd', fieldName: 'cntrTypCd', type: 'text', header: { text: 'TYPE' }, editable: false },
  { name: 'imdunNo1', fieldName: 'imdunNo1', type: 'text', header: { text: 'UN NO.1' }, editable: false },
  { name: 'imoClasCd1', fieldName: 'imoClasCd1', type: 'text', header: { text: 'CLASS' }, editable: false },
  { name: 'imdunNo2', fieldName: 'imdunNo2', type: 'text', header: { text: 'UN NO.2' }, editable: false },
  { name: 'imoClasCd2', fieldName: 'imoClasCd2', type: 'text', header: { text: 'CLASS' }, editable: false },
  {
    name: 'apvStsCd',
    type: 'text',
    fieldName: 'apvStsCd',
    header: {
      text: 'STATUS'
    },
    editable: false,
    renderer: 'renderer_button'
  }
]

export default {
  name: 'DangerShipList',
  components: {
    EBreadcrumbs,
    EAutoCompletePlace,
    EDateRangePicker: () => import('@/components/common/EDateRangePicker'),
    EDatePicker: () => import('@/components/common/EDatePicker'),
    DangerQstDtlPop: () => import('@/pages/trans/popup/DangerQstDtlPop'),
    DangerShipQstPop: () => import('@/pages/trans/popup/DangerShipQstPop'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    UnnoSearchPop: () => import('@/pages/trans/popup/UnnoSearchPop')/* Unno 금지 선사,포트 검색 팝업 */
  },
  data: function () {
    return {
      gridList: [],
      customComponent: null,
      param: {
        asDtKnd: 'DT',
        asFromDt: '',
        asToDt: '',

        asReqNo: '',
        asReqNo1: '',
        asReqNo2: '',
        asReqNo3: '',

        apvStsCd: ['02', '03', '01, 04'],

        asPolPlcCd: '',
        asPodPlcCd: ''
        // asChk1: 'Y',
        // asChk2: 'Y',
        // asChk3: 'Y'
      },
      isPaging1: true,
      currentPage: 0,
      totalPage: 0,
      mUserName: '',
      mNumber: '',
      detail: [],

      dateFrom: '',
      dateTo: '',

      pol: {
        ctrCd: '',
        plcCd: ''
      },
      pod: {
        ctrCd: '',
        plcCd: ''
      },
      startPlcCd: '',
      startPlcName: '',
      destPlcCd: '',
      startCtrCd: '',
      destCtrCd: '',
      destPlcName: '',
      startPolName: '',
      destPodName: '',

      templateStr: '',
      validationDt: false,
      relYn: true
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    window.functionOutsideVue(this)
    window.thisDanger = this
    // 검색 날짜 최근 3개월 세팅
    this.optionChange()
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  mounted: function () {
    console.log('mounted')
    //엑셀 다운로드용 JSZip 셋팅
    window.JSZip = window.JSZip || JSZip

    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    // 그리드 필드 생성
    provider.setFields(fields)
    // columns 명
    gridView.setColumns(columns)
    //gridView.setColumnLayout(layout)
    gridView.setDataSource(provider)

    // 헤더 높이 조정
    gridView.header.heights = [45]
    gridView.displayOptions.rowHeight = 35
    gridView.displayOptions.fitStyle = 'even'

    // gridView.setDisplayOptions({
    //   rowResizable: true,
    //   rowHeight: 34,
    //   fitStyle: 'evenFill'
    // })

    gridView.setStateBar({
      visible: false
    })
    gridView.setRowIndicator({
      visible: false
    })
    gridView.setCheckBar({
      visible: true,
      exclusive: false
    })
    //페이징
    // gridView.setPaging(true, 10)

    this.customRender()
    this.setMessageProperty()

    const $vm = this

    gridView.onCellItemClicked = function (grid, index, clickData) {
      let checkCurrent = gridView.getCurrent()
      if (checkCurrent.fieldName === 'reqNo') {
         let clickedReqNo = clickData.value
         $vm.openCellPopup('DangerQstDtlPop', clickedReqNo)
      }
    }
    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
    })
  },
  methods: {
    handleKeydown (e) {
      // JamSin
      if (this.relYn) {
        if (e.key === 'Enter') {
          this.searchData()
        }
      }
    },
    setMessageProperty () {
      // 컬럼 헤더 텍스트 적용
      let polPortNm = gridView.getColumnProperty('polPortNm', 'header')
      let podPortNm = gridView.getColumnProperty('podPortNm', 'header')
      let apvStsCd = gridView.getColumnProperty('apvStsCd', 'header')

      polPortNm.text = this.$t('msg.FARE010T010.001')
      podPortNm.text = this.$t('msg.FARE010T010.002')
      apvStsCd.text = this.$t('msg.SPEC030.026')

      gridView.setColumnProperty('polPortNm', 'header', polPortNm)
      gridView.setColumnProperty('podPortNm', 'header', podPortNm)
      gridView.setColumnProperty('apvStsCd', 'header', apvStsCd)
    },
    templateEnter (e) {
      // if (e.keyCode === 13) {
        const gridList = this.gridList
        if (this.templateStr === '') {
          provider.setRows(this.gridList)
        } else {
          this.templateStr = this.templateStr.trim()
          this.gridFilter = gridList.filter(vo =>
                (
                  (this.$ekmtcCommon.isNotEmpty(vo.fstEntDt) && vo.fstEntDt.indexOf(this.templateStr) > -1) ||
                  (this.$ekmtcCommon.isNotEmpty(vo.reqNo) && vo.reqNo.indexOf(this.templateStr) > -1) ||
                  (this.$ekmtcCommon.isNotEmpty(vo.imdunNo1) && vo.imdunNo1.indexOf(this.templateStr) > -1) ||
                  (this.$ekmtcCommon.isNotEmpty(vo.imoClasCd1) && vo.imoClasCd1.indexOf(this.templateStr) > -1) ||
                  (this.$ekmtcCommon.isNotEmpty(vo.imdunNo2) && vo.imdunNo2.indexOf(this.templateStr) > -1) ||
                  (this.$ekmtcCommon.isNotEmpty(vo.imoClasCd2) && vo.imoClasCd2.indexOf(this.templateStr) > -1) ||
                  (this.$ekmtcCommon.isNotEmpty(vo.polPortNm) && vo.polPortNm.indexOf(this.templateStr) > -1) ||
                  (this.$ekmtcCommon.isNotEmpty(vo.podPortNm) && vo.podPortNm.indexOf(this.templateStr) > -1) ||
                  (this.$ekmtcCommon.isNotEmpty(vo.cntrTypCd) && vo.cntrTypCd.indexOf(this.templateStr) > -1) ||
                  (this.$ekmtcCommon.isNotEmpty(vo.apvStsCd) && vo.apvStsCd.indexOf(this.templateStr) > -1) ||
                  (this.$ekmtcCommon.isNotEmpty(vo.cmdtNm) && vo.cmdtNm.indexOf(this.templateStr) > -1)
                )
          )
           provider.setRows(this.gridFilter)
        }
      // }
    },
    changePol (vo) {
      this.pol = vo
      this.startPolName = vo.plcEnm
      this.startCtrCd = vo.ctrCd
      this.startPlcCd = vo.plcCd
      this.startPlcName = vo.plcEnm
      this.param.asPolPlcCd = vo.plcCd
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#polPol')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePod (vo) {
      this.pod = vo
      this.destPodName = vo.plcEnm
      this.destCtrCd = vo.ctrCd
      this.destPlcCd = vo.plcCd
      this.destPlcName = vo.plcEnm
      this.param.asPodPlcCd = vo.plcCd
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#podPod')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changeDateRange (s, d) {
      this.dateFrom = s
      this.dateTo = d

      this.unformattedFrom = moment(s).format('YYYY-MM-DD')
      this.unformattedTo = moment(d).format('YYYY-MM-DD')

      this.checkSearchValidation('fromDtToDt')
    },
    checkSearchValidation (id) {
      const searchParams = this.param
      const frm = document.querySelector('#frm')
      let selector = frm.querySelector('#' + id)
      let isOk = true

      if (selector === null || selector === undefined) {
        return true
      }

      // this.$ekmtcCommon.hideErrorTooltip(selector)
      this.localHideErrorTooltip(selector)

      switch (id) {
        case 'fromDtToDt':
          // if (searchParams.asDtKnd === 'DT' && (this.$ekmtcCommon.isEmpty(searchParams.asFromDt) || this.$ekmtcCommon.isEmpty(searchParams.asToDt))) {
          //   this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          //   isOk = false
          // } else
          var fromDt = new Date(this.unformattedFrom)
          var toDt = new Date(this.unformattedTo)

          if (searchParams.asDtKnd === 'DT' && fromDt > toDt) {
            this.LocalShowErrorTooltip(selector, this.$t('msg.ONEX010T010.128'))
            // this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.ONEX010T010.128'))
            isOk = false
          }
          break
      }
      return isOk
    },
    LocalShowErrorTooltip (elem, message) {
          this.validationDt = true

          let $elem = $(elem)
          let $parent = $elem.parents('td, div').eq(0)
          let $parentTd = $parent.parents('td,div').eq(0)
          let $parentLi = $elem.parents('li').eq(0)

          $elem.addClass('input_error')
          let $sMsgBox = ''
          let $msgBox = ''

          // $parentTd.addClass('position_relative')
          $msgBox = $parent.find('.tooltip_essen_box')

          if ($sMsgBox.length === 0) {
            $msgBox = $('<p class=\'tooltip_essen_box\'><span class=\'tooltip_essen\'></span></p> ')
            $msgBox.appendTo($parentLi)
          }
          $msgBox.find('.tooltip_essen').eq(0).text(message).show()
    },
    localHideErrorTooltip (elem) {
          this.validationDt = false
          const $elem = $(elem)
          const $parent = $elem.parents('td, div').eq(0)
          let $parentLi = $elem.parents('li').eq(0)

          $elem.removeClass('input_error')
          $parentLi.find('.tooltip_essen').hide()
    },
    searchData (msg) {
      // 2021.11.02 재조회시 결과내검색(필터) 초기화
      this.templateStr = ''

      let searchk = ''

      if (msg === 'closeResearch') {
        searchk = true
      } else {
        searchk = this.optionChk()
      }
      this.isSearch = true

      if (this.dateFrom !== '' && this.dateTo !== '') {
        var from = this.dateFrom.split('.').join('')
        var to = this.dateTo.split('.').join('')

        this.param.asFromDt = from
        this.param.asToDt = to
      }

     if (searchk) {
        dangerShipList.getDangerShipList(this.param).then(response => {
          this.gridList = response.data.dgList
          provider.setRows(this.gridList)
          //paging init
          this.currentPage = 0
          this.totalPage = gridView.getPageCount()
          gridView.setPage(0)
        }).catch(err => {
          console.log(err)
        })
     }
    },
    //상세 페이지 활성화
    moveDetail (reqNo) {
      console.log('moveDetail')
      //rest api 호출
      const detail = Oogs.getOogDetail(reqNo)

      detail.then((data) => {
          const detailData = data.data
          this.detail = detailData
      })
    },
    openCellPopup (compNm, reqNo) {
      this.customComponent = compNm

      if (compNm === 'DangerQstDtlPop') { // 버튼 클릭 상세
        // 그리드 값 가져오기
        let rowCnt = provider.getRows(0).length
        var checkedItems = gridView.getCheckedItems(true)

        this.detail = []
        // checkedItems.forEach((item, idx) => {
          this.detail[0] = reqNo
        // })
      }
      // 팝업 후처리
      $('#layerPop > .popup_dim').fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    openPopup (compNm, kind, reqNo) {
      console.log('openPopup')
      if (compNm === 'DangerQstDtlPop') { // 버튼 클릭 상세
        // 그리드 값 가져오기
        let rowCnt = provider.getRows(0).length
        // var checkedItems = gridView.getCheckedItems(true)
        let rows = gridView.getCheckedRows(true)

        if (rows.length === 0) {
          this.openAlert('Please select request no')
          return
        } else {
            this.detail = []
            rows.forEach((item, idx) => {
              this.detail[idx] = provider.getValue(item, 'reqNo')
            })
        }
      }
      if (kind === 'DangerShipQstPop') {
        //this.detail = []
      }
      // 팝업 후처리
      this.customComponent = compNm
      $('#layerPop > .popup_dim').fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    closePoup (responseReqNo) {
       if (responseReqNo !== 'X') {
        //this.detail[0] = responseReqNo
        this.detail = []
        if (this.$ekmtcCommon.isNotEmpty(responseReqNo)) {
          this.detail = responseReqNo.split(',')
        }
        this.customComponent = 'DangerQstDtlPop'
        $('#layerPop > .popup_dim').fadeIn()
        $('body,html').css('overflow', 'hidden')
        responseReqNo = ''
        // 데이터 재조회
        this.searchData('closeResearch')
       } else {
        this.customComponent = null
        $('#layerPop > .popup_dim').fadeOut()
        $('body,html').removeAttr('style')
       }
    },
    closeFun () {
      this.$Simplert.close()
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.SPEC010G010.009'), // 확인
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    optionChange () {
      if (this.param.asDtKnd === 'DT') {
          // startDate 세팅
          var sdate = new Date()
          var startDate = new Date(sdate.setMonth(sdate.getMonth() - 3))

          var edate = new Date()
          var endDate = new Date(edate.getFullYear(), edate.getMonth() + 1, edate.getDate())
          var month = ''
          var day = ''

          if (String(startDate.getMonth()) === '0') {
            month = '01'
          } else {
            if (String(startDate.getMonth() + 1).length === 1) {
              month = '0' + String(startDate.getMonth() + 1)
            } else {
              month = String(startDate.getMonth() + 1)
            }
          }

          if (String(startDate.getDate()).length === 1) {
            day = '0' + String(startDate.getDate())
          } else {
            day = String(startDate.getDate())
          }

          if (month === '01' && day < 15) {
            this.dateFrom = startDate.getFullYear() + '.' + month + '.' + day

            // datePicker 전달 value
            this.unformattedFrom = startDate.getFullYear() + month + day
          } else {
            this.dateFrom = startDate.getFullYear() + '.' + month + '.' + day

            // datePicker 전달 value
            this.unformattedFrom = startDate.getFullYear() + month + day
          }

          if (String(endDate.getMonth()) === '0') {
            month = '12'
          } else {
            if (String(endDate.getMonth()).length === 1) {
              month = '0' + String(endDate.getMonth())
            } else {
              month = String(endDate.getMonth())
            }
          }

          if (String(endDate.getDate()).length === 1) {
            day = '0' + String(endDate.getDate())
          } else {
            day = String(endDate.getDate())
          }

          this.dateTo = edate.getFullYear() + '.' + month + '.' + day

          // datePicker 전달 value
          this.unformattedTo = edate.getFullYear() + month + day

          // 메뉴 선택 변경시 값 초기화
          this.param.asReqNo1 = ''
      }
    },
    optionChk: function () {
      if (this.param.asDtKnd === 'RQ') {
        if (this.param.asReqNo1 === '' && this.param.asReqNo2 === '' && this.param.asReqNo3 === '') {
            this.openAlert('Please input request no.')
            return false
        }
      } else if (this.param.asDtKnd === 'DT') {
        if (this.dateFrom === '') {
            this.openAlert('Please input start date.')
            return false
        }
        if (this.dateTo === '') {
            this.openAlert('Please input end date.')
            return false
        }
      }

      return true
    },
    searchAllByDt () {
      this.param.asDtKnd = 'DT'
      this.optionChange()
      this.searchData()
    },
    customRender: function () {
      const asking = this.$t('msg.CSBK140.001') //요청중
      const pass = this.$t('msg.CSBK140.002') // 선적가능
      const nPass = this.$t('msg.CSBK140.003') // 선적불가
      const cheking = this.$t('msg.CSBK140.004') // 확인중

      gridView.registerCustomRenderer('renderer_button', {
        initContent: function (parent) {
          var span = this._span = document.createElement('span')
          parent.appendChild(span)
        },
        render: function (grid, model, width, height, info) {
          info = info || {}
          var span = this._span
          // text설정.
          // span.className = 'button blue sm'
          span.textContent = model.value

          if (span.textContent === '01') {
              span.className = 'label green md minwid60'
              span.textContent = asking
          } else if (span.textContent === '02') {
              span.className = 'label blue md minwid60'
              span.textContent = pass
          } else if (span.textContent === '03') {
              span.className = 'label red md minwid60'
              span.textContent = nPass
          } else {
              span.className = 'label gray md minwid60'
              span.textContent = cheking
          }
        }
      })
    },
    setPrevPage: function () {
      gridView.setPage(this.currentPage - 1)
      this.currentPage = gridView.getPage()
    },
    setNextPage: function () {
      gridView.setPage(this.currentPage + 1)
      this.currentPage = gridView.getPage()
    },
/*    exlDownload () { // 그리드 엑셀 다운로드
      let title = `${this.$t('menu.MENU04.000')}_${this.$t('menu.MENU04.010')}` // 진행중인업무_수입
      this.$ekmtcCommon.gridExportExcel(gridView, title)
    },*/
    exlDownload () {
      const curDt = moment().format('YYYYMMDD')
      gridView.exportGrid({
        type: 'excel',
        target: 'local',
        textCallback: function (index, column, value) {
          if (column === 'apvStsCd') {
            if (value === '01') {
              return '요청중'
            } else if (value === '02') {
              return '선적가능'
            } else if (value === '03') {
              return '선적불가'
            } else {
              return '확인중' // 02, 03은 확인 했으나 나머지는 확인 안됨
            }
          } else {
            return value
          }
        },
        documentTitle: { //제목
          message: this.$t('menu.MENU04.000') + '_' + this.$t('menu.MENU04.010'),
          visible: true,
          spaceTop: 1,
          spaceBottom: 1,
          height: 60,
          styleName: 'excel-export-title-style'
        },
//        applyDynamicStyles: true,
        exportTemplate: true,
//        allColumns: true,
        pagingAllItems: true,
        fileName: `${this.$t('menu.MENU04.000') + '_' + this.$t('menu.MENU04.010')}_${curDt}.xlsx`,
        showProgress: false,
        progressMessage: this.$t('msg.ADD20220219.012'), // 엑셀 Export중입니다.
        indicator: 'default',
        header: 'default',
        footer: 'default',
        done: function () {
          console.log('엑셀 다운로드 완료')
        }
      })
    }
  }
}
</script>
<style scoped>ul.grid{
 position:absolute;
}

span.float {
  float:right;
}

.tbl_search td {
  padding: 4px 10px 0px 10px !important;
}

.desc_list ul li:before {
    content: '';
}

.desc_list ul li {
    padding-left: 0px !important;
}
</style>
