import Send from '../../trans.client.js'

export default {
    getDangerShipList (param) {
        return Send({
            // url: 'http://localhost:9006/trans/dg',
            url: '/trans/dg',
            method: 'get',
            params: {
                asDtKnd: param.asDtKnd,
                asFromDt: param.asFromDt,
                asReqNo1: param.asReqNo1,
                asReqNo2: param.asReqNo2,
                asReqNo3: param.asReqNo3,
                asToDt: param.asToDt,
                polPlcCd: param.asPolPlcCd,
                podPlcCd: param.asPodPlcCd,
                status: param.apvStsCd.join(',')

            }
        })
    },
    getDangerShipDtl (param) {
        return Send({
            //url: 'http://localhost:9006/trans/dg/info',
            url: '/trans/dg/info',
            method: 'get',
            params: {
                reqNo: param
            }
        })
    },
    getContainerType () {
        return Send({
            //url: 'http://localhost:9006/trans/dg/containerType',
            url: '/trans/dg/containerType',
            method: 'get',
            params: {
            }
        })
    },
    getUno (param) {
        return Send({
            //url: 'http://localhost:9006/trans/dg/uno',
            url: '/trans/dg/uno',
            method: 'get',
            params: {
              imdgSeq: '0',
              imdunNo: param
            }
        })
    },
    saveDg (data) {
        return Send({
            //url: 'http://localhost:9006/trans/dg',
            url: '/trans/dg',
            method: 'put',
            data: {
              arrImSeq: data.arrImSeq,
              arrMsdsSeq: data.arrMsdsSeq,
              arrUnno: data.arrUnno,
              cntrTypCd: data.cntrTypCd,
              condCd: data.asCondCd,
              podPortCd: data.podPortCd,
              polPortCd: data.polPortCd
            }
        })
    },
    fileSave (data) {
        return Send({
            //url: 'http://localhost:9006/trans/dg/filesave',
            url: '/trans/dg/filesave',
            method: 'put',
            data: data
        })
    }
}
