var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content resize" },
    [
      _c("e-breadcrumbs"),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("msg.SETT070G010.001"))),
      ]),
      _c("div", { staticClass: "content_box" }, [
        _c("table", { staticClass: "tbl_search" }, [
          _vm._m(0),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT070G010.002")))]),
              _vm._v(" "),
              _c("td", { style: { display: "flex" } }, [
                _c("input", {
                  style: { width: "100px" },
                  attrs: { type: "text", value: "KMTC", disabled: "disabled" },
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.param.blNo,
                      expression: "param.blNo",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.param.blNo },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.searchData.apply(null, arguments)
                    },
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.param, "blNo", $event.target.value)
                      },
                      function ($event) {
                        _vm.param.blNo = _vm.param.blNo.toUpperCase()
                      },
                    ],
                  },
                }),
              ]),
              _c("td", { staticClass: "text_left" }, [
                _c(
                  "a",
                  {
                    staticClass: "button blue sh",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.searchData.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.CMBA100.00120")))]
                ),
              ]),
              _vm.isSearched
                ? _c("td", { staticClass: "text_right" }, [
                    _c("span", { staticClass: "color_blue font_medium" }, [
                      _vm._v(_vm._s(_vm.$t("msg.SETT070G010.003"))),
                    ]),
                    _vm._v(" "),
                    _vm.list.length > 0
                      ? _c("span", { staticClass: "ml10" }, [
                          _vm._v(_vm._s(_vm.list[0].salNm)),
                        ])
                      : _c("span", { staticClass: "ml10" }),
                    _vm.list.length > 0
                      ? _c("span", { staticClass: "ml10" }, [
                          _vm._v(_vm._s(_vm.list[0].salTelNo)),
                        ])
                      : _c("span", { staticClass: "ml10" }),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content_box mt10" }, [
        _c("span", { staticClass: "content_title_span" }, [
          _vm._v(_vm._s("작성가이드")),
        ]),
        _c("div", { staticClass: "cont text_left" }, [
          _c(
            "ul",
            {
              staticClass: "bul_list_sm",
              staticStyle: { "list-style": "none" },
            },
            [
              _c("li", [
                _vm._v(" 1. Charged Place: 수리비 발생 지역 "),
                _c("br"),
                _vm._v(" 2. CUR: 인보이스 내 발생 통화 "),
                _c("br"),
                _vm._v(" 3. Repair Charge: 컨테이너 Damage 수리 관련 비용 "),
                _c("br"),
                _vm._v(
                  " 4. Claening Charge: Water washing, Chemical cleaning, Sanding, Painting, Air clean, Steam Clean, Polish Clean 등 "
                ),
                _c("br"),
                _vm._v(" 5. Status: "),
                _c("br"),
                _vm._v(" - " + _vm._s(_vm.$t("msg.SETT070G010.006")) + " "),
                _c("br"),
                _vm._v(" - " + _vm._s(_vm.$t("msg.SETT070G010.007")) + " "),
                _c("br"),
                _vm._v(" - " + _vm._s(_vm.$t("msg.SETT070G010.008")) + " "),
                _c("br"),
                _vm._v(
                  " 6. EOR(Estimate of Repair): " +
                    _vm._s(_vm.$t("msg.SETT070G010.009")) +
                    " "
                ),
                _c("br"),
                _vm._v(
                  " 7. Picture: " +
                    _vm._s(
                      "Damage 관련 사진, 총합 100USD 이상 요청건일 시 필수 첨부 조건"
                    ) +
                    " "
                ),
              ]),
            ]
          ),
        ]),
        _c(
          "h2",
          {
            staticClass: "content_title",
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
              position: "relative",
            },
          },
          [
            _c("span", { staticClass: "content_title_span" }, [
              _vm._v(_vm._s(_vm.$t("msg.SETT070G010.004")) + " "),
              _vm.prdYn
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.txtFilter,
                        expression: "txtFilter",
                      },
                    ],
                    staticClass: "wid500",
                    attrs: {
                      type: "text",
                      id: "filter",
                      autocomplete: "off",
                      placeholder: _vm.$t("msg.SETT070G010.036"),
                    },
                    domProps: { value: _vm.txtFilter },
                    on: {
                      keyup: function ($event) {
                        return _vm.changeFilter()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.txtFilter = $event.target.value
                      },
                    },
                  })
                : _vm._e(),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "table",
          { staticClass: "tbl_col" },
          [
            _vm._m(1),
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "pr0", attrs: { scope: "col" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.isCheckAll,
                        expression: "isCheckAll",
                      },
                    ],
                    attrs: { id: "All", type: "checkbox", name: "no" },
                    domProps: {
                      checked: Array.isArray(_vm.isCheckAll)
                        ? _vm._i(_vm.isCheckAll, null) > -1
                        : _vm.isCheckAll,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.checkAll()
                      },
                      change: function ($event) {
                        var $$a = _vm.isCheckAll,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.isCheckAll = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.isCheckAll = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.isCheckAll = $$c
                        }
                      },
                    },
                  }),
                  _vm._m(2),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.SETT070G010.017"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.SETT070G010.018"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.SETT070G010.019"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.SETT070G010.020"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("msg.SETT070G010.021")) + " "),
                  _c("span", { staticClass: "position_relative" }, [
                    false
                      ? _c(
                          "button",
                          {
                            staticClass: "tbl_icon help",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.helpClick("cleaningChg")
                              },
                              blur: function ($event) {
                                return _vm.helpClick("cleaningChg")
                              },
                            },
                          },
                          [_vm._v("도움말")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "tooltip_wrap short position_absolute",
                        style: _vm.isShowCleaningChg
                          ? "width:270px; left:0; top:24px;"
                          : "display:none;",
                        attrs: { id: "cleaningChg" },
                      },
                      [
                        _c("div", { staticClass: "cont text_left" }, [
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.helpClick("cleaningChg")
                                },
                              },
                            },
                            [_vm._v("close")]
                          ),
                          _c("p", { staticClass: "title" }, [
                            _vm._v("Cleaning Charge"),
                          ]),
                          _c("ul", { staticClass: "bul_list_sm" }, [
                            _c("li", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("msg.SETT070G010.005")) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v("Total Charges(USD)"),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("msg.SETT070G010.024")) + " "),
                  _c("span", { staticClass: "position_relative" }, [
                    false
                      ? _c(
                          "button",
                          {
                            staticClass: "tbl_icon help",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.helpClick("status")
                              },
                              blur: function ($event) {
                                return _vm.helpClick("status")
                              },
                            },
                          },
                          [_vm._v("도움말")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "tooltip_wrap short position_absolute",
                        style: _vm.isShowStatus
                          ? "width:270px; left:0; top:24px;"
                          : "display:none;",
                        attrs: { id: "status" },
                      },
                      [
                        _c("div", { staticClass: "cont text_left" }, [
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.helpClick("status")
                                },
                              },
                            },
                            [_vm._v("close")]
                          ),
                          _c("p", { staticClass: "title" }, [
                            _vm._v(_vm._s(_vm.$t("msg.SETT070G010.024"))),
                          ]),
                          _c("ul", { staticClass: "bul_list_sm" }, [
                            _c("li", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("msg.SETT070G010.006")) +
                                  " "
                              ),
                            ]),
                            _c("li", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("msg.SETT070G010.007")) +
                                  " "
                              ),
                            ]),
                            _c("li", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("msg.SETT070G010.008")) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.SETT070G010.025"))),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(" EOR "),
                  _c("span", { staticClass: "position_relative" }, [
                    false
                      ? _c(
                          "button",
                          {
                            staticClass: "tbl_icon help",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.helpClick("EOR")
                              },
                              blur: function ($event) {
                                return _vm.helpClick("EOR")
                              },
                            },
                          },
                          [_vm._v("도움말")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "tooltip_wrap short position_absolute",
                        style: _vm.isShowEOR
                          ? "width:270px; left:0; top:24px;"
                          : "display:none;",
                        attrs: { id: "EOR" },
                      },
                      [
                        _c("div", { staticClass: "cont text_left" }, [
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.helpClick("EOR")
                                },
                              },
                            },
                            [_vm._v("close")]
                          ),
                          _c("p", { staticClass: "title" }, [
                            _vm._v("EOR(Estimate of Repair) "),
                          ]),
                          _c("ul", { staticClass: "bul_list_sm" }, [
                            _c("li", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("msg.SETT070G010.009")) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("msg.SETT070G010.026")) + " "),
                  _c("span", { staticClass: "position_relative" }, [
                    false
                      ? _c(
                          "button",
                          {
                            staticClass: "tbl_icon help",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.helpClick("picture")
                              },
                              blur: function ($event) {
                                return _vm.helpClick("picture")
                              },
                            },
                          },
                          [_vm._v("도움말")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "tooltip_wrap short position_absolute",
                        style: _vm.isShowPicture
                          ? "width:270px; left:0; top:24px;"
                          : "display:none;",
                        attrs: { id: "picture" },
                      },
                      [
                        _c("div", { staticClass: "cont text_left" }, [
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.helpClick("picture")
                                },
                              },
                            },
                            [_vm._v("close")]
                          ),
                          _c("p", { staticClass: "title" }, [
                            _vm._v("Picture "),
                          ]),
                          _c("ul", { staticClass: "bul_list_sm" }, [
                            _c("li", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("msg.ADD20220221.023")) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "col", COLSPAN: "11" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("msg.SETT070G010.022"))),
                ]),
              ]),
            ]),
            _vm.list.length > 0
              ? _vm._l(_vm.list, function (item, idx) {
                  return _c("tbody", { key: idx + "1" }, [
                    _c(
                      "tr",
                      [
                        _c("td", { staticClass: "pr0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.isChecked,
                                expression: "item.isChecked",
                              },
                            ],
                            attrs: { id: "chk-" + idx, type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(item.isChecked)
                                ? _vm._i(item.isChecked, null) > -1
                                : item.isChecked,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = item.isChecked,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        item,
                                        "isChecked",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        item,
                                        "isChecked",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(item, "isChecked", $$c)
                                }
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "chk-" + idx } }, [
                            _c("span", { staticClass: "offscreen" }, [
                              _vm._v("체크"),
                            ]),
                          ]),
                        ]),
                        _c("td", [_vm._v(_vm._s(item.cntrNo))]),
                        _c("td", [_vm._v(_vm._s(item.reprPlcCd))]),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.curCd,
                                  expression: "item.curCd",
                                },
                              ],
                              attrs: {
                                disabled:
                                  item.editFlag === "N" || !item.isChecked,
                                id: "sel-" + idx,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      item,
                                      "curCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function ($event) {
                                    return _vm.calculate(idx)
                                  },
                                ],
                              },
                            },
                            [
                              item.curCd === "USD" && item.dlyCurCd === "USD"
                                ? [
                                    _c("option", { attrs: { value: "USD" } }, [
                                      _vm._v("USD"),
                                    ]),
                                  ]
                                : [
                                    _c(
                                      "option",
                                      {
                                        domProps: {
                                          value: item.dlyCurCd,
                                          selected:
                                            item.dlyCurCd === item.curCd,
                                        },
                                      },
                                      [_vm._v(_vm._s(item.dlyCurCd))]
                                    ),
                                    _c(
                                      "option",
                                      {
                                        attrs: { value: "USD" },
                                        domProps: {
                                          selected: "USD" === item.curCd,
                                        },
                                      },
                                      [_vm._v("USD")]
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ]),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              staticClass: "text_right",
                              attrs: {
                                readonly: item.editFlag === "N",
                                disabled: !item.isChecked,
                                point: 3,
                                "digit-max": 15,
                                id: "input_repr-" + idx,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.calculate(idx)
                                },
                              },
                              model: {
                                value: item.reprAmt,
                                callback: function ($$v) {
                                  _vm.$set(item, "reprAmt", $$v)
                                },
                                expression: "item.reprAmt",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              staticClass: "text_right",
                              attrs: {
                                readonly: item.editFlag === "N",
                                disabled: !item.isChecked,
                                point: 3,
                                "digit-max": 15,
                                id: "input_cln-" + idx,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.calculate(idx)
                                },
                              },
                              model: {
                                value: item.clnAmt,
                                callback: function ($$v) {
                                  _vm.$set(item, "clnAmt", $$v)
                                },
                                expression: "item.clnAmt",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("td", { staticClass: "text_right" }, [
                          _vm._v(_vm._s(item.totAmtUsd)),
                        ]),
                        [
                          item.oldWavStsCd === "00"
                            ? _c("td", [_vm._v("Request")])
                            : item.wavStsCd === "01" ||
                              item.wavStsCd === "06" ||
                              item.wavStsCd === "05"
                            ? _c("td", [_vm._v("Checking")])
                            : item.wavStsCd === "02" ||
                              item.wavStsCd === "03" ||
                              item.wavStsCd === "04"
                            ? _c("td", [_vm._v("Complete")])
                            : _c("td"),
                        ],
                        [
                          item.wavStsCd === "02" ||
                          item.wavStsCd === "03" ||
                          item.wavStsCd === "04" ||
                          item.wavStsCd === "05"
                            ? _c("td", [_vm._v(_vm._s(item.dcRate))])
                            : _c("td", [_vm._v(_vm._s(item.dcRate))]),
                        ],
                        _c("td", [
                          item.isChecked
                            ? _c("span", [
                                item.eorYn === "Y"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "button red sm",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.openFileUploader(
                                              "01",
                                              item.cntrNo,
                                              idx,
                                              item.wavStsCd,
                                              item.rejectYn
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("File")]
                                    )
                                  : _c(
                                      "a",
                                      {
                                        staticClass: "button sm",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.openFileUploader(
                                              "01",
                                              item.cntrNo,
                                              idx,
                                              item.wavStsCd,
                                              item.rejectYn
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("File")]
                                    ),
                              ])
                            : _c("span", [
                                item.eorYn === "Y"
                                  ? _c("a", { staticClass: "button red sm" }, [
                                      _vm._v("File"),
                                    ])
                                  : _c("a", { staticClass: "button sm" }, [
                                      _vm._v("File"),
                                    ]),
                              ]),
                        ]),
                        _c("td", [
                          item.isChecked
                            ? _c("span", [
                                item.picYn === "Y"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "button red sm",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.openFileUploader(
                                              "02",
                                              item.cntrNo,
                                              idx,
                                              item.wavStsCd,
                                              item.rejectYn
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("File")]
                                    )
                                  : _c(
                                      "a",
                                      {
                                        staticClass: "button sm",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.openFileUploader(
                                              "02",
                                              item.cntrNo,
                                              idx,
                                              item.wavStsCd,
                                              item.rejectYn
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("File")]
                                    ),
                              ])
                            : _c("span", [
                                item.picYn === "Y"
                                  ? _c("a", { staticClass: "button red sm" }, [
                                      _vm._v("File"),
                                    ])
                                  : _c("a", { staticClass: "button sm" }, [
                                      _vm._v("File"),
                                    ]),
                              ]),
                        ]),
                      ],
                      2
                    ),
                    _c("tr", [
                      item.isChecked
                        ? _c("td", { attrs: { colspan: "11" } }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.reqCont,
                                  expression: "item.reqCont",
                                },
                              ],
                              attrs: {
                                readOnly: item.editFlag === "N",
                                id: "text-" + idx,
                              },
                              domProps: { value: item.reqCont },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(item, "reqCont", $event.target.value)
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                })
              : [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "11" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSCT060G030.022"))),
                      ]),
                    ]),
                  ]),
                ],
          ],
          2
        ),
        _c("table", { staticClass: "tbl_row mt10" }, [
          _vm._m(3),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT070G010.027")))]),
              _vm._v(" "),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.send.reqCstEmlAddr,
                      expression: "send.reqCstEmlAddr",
                    },
                  ],
                  attrs: { type: "text", placeholder: "" },
                  domProps: { value: _vm.send.reqCstEmlAddr },
                  on: {
                    keyup: function ($event) {
                      return _vm.fnEmailValidate()
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.send, "reqCstEmlAddr", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "desc_list" }, [
          _c("ul", [_c("li", [_vm._v(_vm._s(_vm.$t("msg.SETT070G010.010")))])]),
        ]),
      ]),
      _c("div", { staticClass: "btn_area top" }, [
        _c("div", { staticClass: "btn_right" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.openPopup("DataUploadPop")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.SETT070G010.011")))]
          ),
          _c(
            "a",
            { staticClass: "button blue lg", on: { click: _vm.saveData } },
            [_vm._v(_vm._s(_vm.$t("msg.SETT070G010.012")))]
          ),
          _c(
            "a",
            { staticClass: "button gray lg", on: { click: _vm.cancel } },
            [_vm._v(_vm._s(_vm.$t("msg.SETT070G010.028")))]
          ),
        ]),
      ]),
      _c("br"),
      _c("br"),
      _c(
        "div",
        { attrs: { id: "layerPop" } },
        [
          _c(
            "win-layer-pop",
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: {
                      "parent-info": _vm.uploadParam,
                      "is-show-pop": _vm.isShowPop,
                    },
                    on: { close: _vm.closePopup, selectFunc: _vm.selectFunc },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { id: "uploadPop" } },
        [
          _c(
            "win-layer-pop",
            [
              _vm.customComponent1
                ? _c(_vm.customComponent1, {
                    tag: "component",
                    attrs: { "send-info": _vm.send, "single-file-yn": true },
                    on: { close: _vm.closePopup, selectFunc: _vm.selectFunc },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "80px" } }),
      _c("col", { attrs: { width: "400px" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "250px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "40px" } }),
      _c("col", { staticStyle: { width: "13%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "All" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("All")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "14%" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }