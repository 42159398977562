import Send from '../../settle.client.js'

export default {
    getWrWaiverList (param) {
        return Send({
            // url: 'http://localhost:9008/settle/mrwaiver',
            url: '/settle/mrwaiver',
            method: 'get',
            params: {
                blNo: param.blNo
            }
        })
    },
    saveMrSave (data) {
        return Send({
            url: '/settle/mrwaiver/mrsave',
            method: 'post',
            data: data
        })
    },
    mrcancel (param) {
        return Send({
            // url: 'http://localhost:9008/settle/mrwaiver/mrcancel',
            url: '/settle/mrwaiver/mrcancel',
            method: 'post',
            params: {
                blNo: param.blNo
            }
        })
    },
    amtusd (param) {
        return Send({
            // url: 'http://localhost:9008/settle/mrwaiver/amtusd',
            url: '/settle/mrwaiver/amtusd',
            method: 'get',
            spinner: false,
            params: param
        })
    },
    getFileList (param) {
        return Send({
            url: '/settle/mrwaiver/filelist',
            method: 'get',
            params: {
                blNo: param.blNo,
                cntrNo: param.cntrNo,
                fileKind: param.fileKndCd
            }
        })
    },
    setFileSave (data) {
        return Send({
            url: '/settle/mrwaiver/filesave',
            method: 'post',
            data: data
        })
    },
    getExcelUpload (param) {
        return Send({
            url: '/settle/mrwaiver/excelup',
            method: 'get',
            params: {
                blNo: param.blNo,
                fileId: param.fileId
            }
        })
    },
    getExcelDownload (data) {
        return Send({
            //exceldown'
            url: '/settle/mrwaiver/exceldown',
            method: 'post',
            responseType: 'blob',
            data: data
        })
    }
}
