<template>
  <div class="popup_wrap" style="width:700px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.SETT070G010.011') }}</h1>
      <div class="content_box">
        <p>
          {{ $t('msg.SETT070G010.016') }}
        </p>
        <table class="tbl_row mt10">
          <colgroup>
            <col width="110px">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <td><a class="button sm" href="#" @click.prevent="fileDownLoad">DownLoad</a></td>
              <td class="text_left">
                <DEXT5Upload
                  ref="dextupload_excel"
                  :id="component_id"
                  :category="false"
                  width="100%"
                  height="150px"
                  :single="singleFileYn"
                  :file-info="parentInfo"
                  :category-info="[]"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt10 text_center">
        <a class="button gray lg" href="#" @click="popClose()">{{ $t('msg.SETT070G010.015') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import DEXT5Upload from '@/components/common/DEXT5UploadFor'
import WrWaiverList from '@/api/rest/settle/wrWaiverList'

export default {
  name: 'MrWaiverExcelPop',
  components: {
                DEXT5Upload,
                WrWaiverList
              },
  props: {
    parentInfo: {
      type: Array,
      default: function () {
        return []
      }
    },
    singleFileYn: {
      type: Boolean,
      default: false
    },
    sendInfo: {
      type: Object,
      default: function () {
        return []
      }
    }
  },
  data: function () {
    return {
      param: {
        blNo: '',
        fileId: 0
      },
      paramData: {}
    }
  },
  computed: {
    component_id () {
      if (typeof window.upload_component_cnt === 'undefined') {
        window.upload_component_cnt = 0
      }
      window.upload_component_cnt++
      const c = window.upload_component_cnt

      return `upload_com_id_${c}`
    }
  },
  watch: {
  },
  created () {
    //window.functionOutsideVue(this)
  },
  mounted: function () {
    const $vm = this
  },
  methods: {
    fileDownLoad () {
      console.log('response=====' + JSON.stringify(this.sendInfo))
      WrWaiverList.getExcelDownload(this.sendInfo).then(response => {
        console.log('response=====' + JSON.stringify(this.response))
        const name = response.headers['content-disposition'].split('filename=')[1]
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        link.remove()
      }).catch(err => {
        console.log(err)
      })
    },
    setFileInfo: function (parentInfo) {
      console.log('setFileInfo')
      //파일저장
      console.log('parentInfo = ' + JSON.stringify(parentInfo))
      // this.param.blNo = this.sendInfo.blNo//'AMD0014899'//
      // this.param.fileId = parentInfo.fileId//1291//
      // WrWaiverList.getExcelUpload(this.param).then(response => {
      // console.log('response = ' + JSON.stringify(response))
      // //this.parentInfo = response.data.waiverList
      // this.$emit('selectFunc', response.data.waiverList)
      // this.$emit('close')
      // }).catch(err => {
      //   console.log(err)
      // })
    },
    popClose: function () {
      let file = this.$refs.dextupload_excel.getFileInfo()
      this.$refs.dextupload_excel.ResetDext()
      console.log('popClose =====>')
      console.log(file)
      if (file.length > 0) {
        this.param.blNo = this.sendInfo.blNo
        this.param.fileId = file[0].fileId
        WrWaiverList.getExcelUpload(this.param).then(response => {
          console.log('response = ' + JSON.stringify(response))
          this.$emit('selectFunc', response)
          this.$emit('close')
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.$emit('close')
      }

      return false
    }
  }
}
</script>
