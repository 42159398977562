var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "700px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.SETT070G010.011"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("p", [_vm._v(" " + _vm._s(_vm.$t("msg.SETT070G010.016")) + " ")]),
          _c("table", { staticClass: "tbl_row mt10" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("td", [
                  _c(
                    "a",
                    {
                      staticClass: "button sm",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.fileDownLoad.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("DownLoad")]
                  ),
                ]),
                _c(
                  "td",
                  { staticClass: "text_left" },
                  [
                    _c("DEXT5Upload", {
                      ref: "dextupload_excel",
                      attrs: {
                        id: _vm.component_id,
                        category: false,
                        width: "100%",
                        height: "150px",
                        single: _vm.singleFileYn,
                        "file-info": _vm.parentInfo,
                        "category-info": [],
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.popClose()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.SETT070G010.015")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "110px" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }