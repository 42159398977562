<template>
  <div class="popup_wrap" style="width:800px; height:520px;">
    <button
      class="layer_close"
      @click="popClose()"
    >
      close
    </button>
    <div class="popup_cont">
      <h1 class="page_title">
        {{ $t('msg.SETT070G010.014') }}
      </h1>
      <div class="content_box">
        <DEXT5Upload
          ref="dextupload_mr"
          :id="component_id"
          width="100%"
          height="200px"
          :category="false"
          :single="singleFileYn"
          :file-info="parentInfo"
          :is-show-pop="isShowPop"
          :category-info="[]"
        />
      </div>
    </div>
    <div class="mt10 text_center">
      <a class="button blue lg" @click="popClose()">{{ $t('msg.SETT070G010.015') }}</a>
    </div>
  </div>
</template>

<script>
import DEXT5Upload from '@/components/common/DEXT5UploadForMr'

export default {
  name: 'MrWaiverUploadPop',
  components: {
    DEXT5Upload,
    EFileUploader: () => import('@/components/common/EFileUploader')
  },
  props: {
    singleFileYn: {
      type: Boolean,
      default: false
    },
    parentInfo: {
      type: Array,
      default: function () {
        return []
      }
    },
    isShowPop: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isShowUpPop: false
    }
  },
  computed: {
    component_id () {
      if (typeof window.upload_component_cnt === 'undefined') {
        window.upload_component_cnt = 0
      }
      window.upload_component_cnt++
      const c = window.upload_component_cnt

      return `upload_com_id_${c}`
    }
  },
  watch: {
    isShowPop: function () {
      // console.log(' watch isShowPop :: ' + this.isShowPop)
      // console.log(this.parentInfo)
    }
  },
  created () {
    console.log('created')
  },
  mounted: function () {
    console.log('mounted')
  },
  methods: {
    setFileInfo: function (parentInfo) {
      let $vm = this
      // console.log('setFileInfo parentInfo')
      // console.log(parentInfo)
      // console.log(this.parentInfo)
      //parentInfo = this.parentInfo
      if (this.$refs.dextupload_mr !== undefined) {
        this.$refs.dextupload_mr.setFileInfo(parentInfo)
      //  // this.$emit('arrange')
      }
    },
    popClose: function () {
      let file = this.$refs.dextupload_mr.getFileInfo()
      // console.log('file ==> ', file)
      //this.$refs.dextupload_mr.ResetDext()
      this.$emit('selectFunc', file)

      this.$emit('close', this.parentInfo)

      return false
    }
  }
}
</script>
<style scoped>
.DEXT_fiVe_UP_ly_wrapper{
  z-index: 99999999 !important;
}
</style>
