<template>
  <div class="content resize">
    <e-breadcrumbs />

    <h1 class="page_title">{{ $t('msg.SETT070G010.001') }}</h1>
    <div class="content_box">
      <table class="tbl_search">
        <colgroup>
          <col width="80px"><col width="400px"><col width="*">
          <col width="250px">
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.SETT070G010.002') }}</th> <!-- B/L No -->
            <td :style="{ display: 'flex' }">
              <input type="text" value="KMTC" disabled="disabled" :style="{ width: '100px' }">
              <input type="text" v-model="param.blNo" @keyup.enter="searchData" @input="param.blNo=param.blNo.toUpperCase()">
            </td>
            <td class="text_left">
              <a class="button blue sh" @click.prevent="searchData">{{ $t('msg.CMBA100.00120') }}</a>
            </td>
            <td class="text_right" v-if="isSearched">
              <span class="color_blue font_medium">{{ $t('msg.SETT070G010.003') }}</span>  <!-- Salesman -->
              <span class="ml10" v-if="list.length > 0">{{ list[0].salNm }}</span><span class="ml10" v-else></span>
              <span class="ml10" v-if="list.length > 0">{{ list[0].salTelNo }}</span><span class="ml10" v-else></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="content_box mt10">
      <span class="content_title_span">{{ '작성가이드' }}</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            1. Charged Place: 수리비 발생 지역
            <br />
            2. CUR: 인보이스 내 발생 통화
            <br />
            3. Repair Charge: 컨테이너 Damage 수리 관련 비용
            <br />
            4. Claening Charge: Water washing, Chemical cleaning, Sanding, Painting, Air clean, Steam Clean, Polish Clean 등
            <br />
            5. Status:
            <br />
            - {{ $t('msg.SETT070G010.006') }}
            <br />
            - {{ $t('msg.SETT070G010.007') }}
            <br />
            - {{ $t('msg.SETT070G010.008') }}
            <br />
            6. EOR(Estimate of Repair): {{ $t('msg.SETT070G010.009') }}
            <br />
            7. Picture: {{ 'Damage 관련 사진, 총합 100USD 이상 요청건일 시 필수 첨부 조건' }}
          </li>
        </ul>
      </div>
      <h2 class="content_title" style="display: flex; justify-content: space-between; position: relative;">
        <span class="content_title_span">{{ $t('msg.SETT070G010.004') }}
          <input v-if="prdYn" type="text" id="filter" class="wid500" v-model="txtFilter" @keyup="changeFilter()" autocomplete="off" :placeholder="$t('msg.SETT070G010.036')">
        </span>
        <!-- <span>{{ $t('msg.SETT070G010.004') }}</span> -->
      </h2> <!-- 요청내역 -->
      <table class="tbl_col">
        <colgroup>
          <col style="width:40px">
          <col style="width:13%">
          <col style="width:10%">
          <col style="width:7%">
          <col style="width:10%">
          <col style="width:10%">
          <col style="width:10%">
          <col style="width:10%">
          <col style="width:9%">
          <col style="width:10%">
          <col style="width:10%">
        </colgroup>
        <thead>
          <tr>
            <th scope="col" class="pr0">
              <input id="All" type="checkbox" name="no" v-model="isCheckAll" @click="checkAll()"><label for="All"><span class="offscreen">All</span></label>
            </th>
            <th scope="col">{{ $t('msg.SETT070G010.017') }}</th> <!-- Container No. -->
            <th scope="col">{{ $t('msg.SETT070G010.018') }}</th> <!-- Charged Place -->
            <th scope="col">{{ $t('msg.SETT070G010.019') }}</th> <!-- Cur. -->
            <th scope="col">{{ $t('msg.SETT070G010.020') }}</th> <!-- Repair Chg. -->
            <th scope="col">
              {{ $t('msg.SETT070G010.021') }} <!-- Cleaning Chg. -->
              <span class="position_relative">
                <button v-if="false" class="tbl_icon help" @click.prevent="helpClick('cleaningChg')" @blur="helpClick('cleaningChg')">도움말</button>
                <div
                  id="cleaningChg"
                  class="tooltip_wrap short position_absolute"
                  :style="isShowCleaningChg ? 'width:270px; left:0; top:24px;' : 'display:none;'"
                >
                  <div class="cont text_left">
                    <button class="close" @click.prevent="helpClick('cleaningChg')">close</button>
                    <p class="title">Cleaning Charge</p>
                    <ul class="bul_list_sm">
                      <li>
                        {{ $t('msg.SETT070G010.005') }} <!-- Water washing, Chemical cleaning, Sanding, Painting, Air clean , Steam Clean, Polish Clean 등 -->
                      </li>
                    </ul>
                  </div>
                </div>
              </span>
            </th>
            <th scope="col">Total Charges(USD)</th>
            <th scope="col">
              {{ $t('msg.SETT070G010.024') }}
              <span class="position_relative">
                <button v-if="false" class="tbl_icon help" @click.prevent="helpClick('status')" @blur="helpClick('status')">도움말</button>
                <div
                  id="status"
                  class="tooltip_wrap short position_absolute"
                  :style="isShowStatus ? 'width:270px; left:0; top:24px;' : 'display:none;'"
                >
                  <div class="cont text_left">
                    <button class="close" @click.prevent="helpClick('status')">close</button>
                    <p class="title">{{ $t('msg.SETT070G010.024') }}</p>
                    <ul class="bul_list_sm">
                      <li>
                        {{ $t('msg.SETT070G010.006') }}
                      </li>
                      <li>
                        {{ $t('msg.SETT070G010.007') }}
                      </li>
                      <li>
                        {{ $t('msg.SETT070G010.008') }}
                      </li>
                    </ul>
                  </div>
                </div>
              </span>
            </th>
            <th scope="col">{{ $t('msg.SETT070G010.025') }}</th>
            <th scope="col">
              EOR
              <span class="position_relative">
                <button v-if="false" class="tbl_icon help" @click.prevent="helpClick('EOR')" @blur="helpClick('EOR')">도움말</button>
                <div
                  id="EOR"
                  class="tooltip_wrap short position_absolute"
                  :style="isShowEOR ? 'width:270px; left:0; top:24px;' : 'display:none;'"
                >
                  <div class="cont text_left">
                    <button class="close" @click.prevent="helpClick('EOR')">close</button>
                    <p class="title">EOR(Estimate of Repair) </p>
                    <ul class="bul_list_sm">
                      <li>
                        {{ $t('msg.SETT070G010.009') }}
                      </li>
                    </ul>
                  </div>
                </div>
              </span>
            </th>
            <th scope="col">
              {{ $t('msg.SETT070G010.026') }} <!-- Picture-->
              <span class="position_relative">
                <button v-if="false" class="tbl_icon help" @click.prevent="helpClick('picture')" @blur="helpClick('picture')">도움말</button>
                <div
                  id="picture"
                  class="tooltip_wrap short position_absolute"
                  :style="isShowPicture ? 'width:270px; left:0; top:24px;' : 'display:none;'"
                >
                  <div class="cont text_left">
                    <button class="close" @click.prevent="helpClick('picture')">close</button>
                    <p class="title">Picture </p>
                    <ul class="bul_list_sm">
                      <li>
                        {{ $t('msg.ADD20220221.023') }}
                      </li>
                    </ul>
                  </div>
                </div>
              </span>
            </th>
          </tr>
          <tr>
            <th scope="col" COLSPAN="11">
              {{ $t('msg.SETT070G010.022') }}<!-- Remark -->
            </th>
          </tr>
        </thead>
        <template v-if="list.length > 0">
          <tbody v-for="(item, idx) in list" :key="idx + '1'">
            <tr>
              <td class="pr0">
                <input :id="'chk-' + idx" type="checkbox" v-model="item.isChecked">
                <label :for="'chk-' + idx"><span class="offscreen">체크</span></label>
              </td>
              <td>{{ item.cntrNo }}</td>
              <td>{{ item.reprPlcCd }}</td>
              <td>
                <select :disabled="item.editFlag === 'N' || !item.isChecked" v-model="item.curCd" @change="calculate(idx)" :id="'sel-' + idx">
                  <template v-if="item.curCd === 'USD' && item.dlyCurCd === 'USD'">
                    <option value="USD">USD</option>
                  </template>
                  <template v-else>
                    <option :value="item.dlyCurCd" :selected="item.dlyCurCd === item.curCd">{{ item.dlyCurCd }}</option>
                    <option value="USD" :selected="'USD' === item.curCd">USD</option>
                  </template>
                </select>
              </td>

              <td>
                <!-- <input type="text" class="text_right" :readonly="item.wavStsCd !== '00'" v-model="item.reprAmt" @blur="calculate(idx)"> -->
                <e-input-number
                  class="text_right"
                  :readonly="item.editFlag === 'N'"
                  :disabled="!item.isChecked"
                  v-model="item.reprAmt"
                  @input="calculate(idx)"
                  :point="3"
                  :digit-max="15"
                  :id="'input_repr-' + idx"
                />
              </td>
              <td>
                <!-- <input type="text" class="text_right" :readonly="item.wavStsCd !== '00'" v-model="item.clnAmt" @blur="calculate(idx)"> -->
                <e-input-number
                  class="text_right"
                  :readonly="item.editFlag === 'N'"
                  :disabled="!item.isChecked"
                  v-model="item.clnAmt"
                  @input="calculate(idx)"
                  :point="3"
                  :digit-max="15"
                  :id="'input_cln-' + idx"
                />
              </td>

              <td class="text_right">{{ item.totAmtUsd }}</td>
              <template>
                <td v-if="item.oldWavStsCd === '00'">Request</td>
                <td v-else-if="item.wavStsCd === '01' || item.wavStsCd === '06' || item.wavStsCd === '05'">Checking</td>
                <td v-else-if="item.wavStsCd === '02' || item.wavStsCd === '03' || item.wavStsCd === '04'">Complete</td>
                <td v-else></td>
              </template>
              <template>
                <td v-if="item.wavStsCd === '02' || item.wavStsCd === '03' || item.wavStsCd === '04' || item.wavStsCd === '05'">{{ item.dcRate }}</td>
                <td v-else>{{ item.dcRate }}</td>
              </template>
              <td>
                <span v-if="item.isChecked">
                  <a v-if="item.eorYn === 'Y'" class="button red sm"
                     @click.prevent="openFileUploader('01', item.cntrNo, idx, item.wavStsCd, item.rejectYn)"
                  >File</a>
                  <a v-else class="button sm" @click.prevent="openFileUploader('01', item.cntrNo, idx, item.wavStsCd, item.rejectYn)">File</a>
                </span>
                <span v-else>
                  <a v-if="item.eorYn === 'Y'" class="button red sm">File</a>
                  <a v-else class="button sm">File</a>
                </span>
              </td>
              <td>
                <span v-if="item.isChecked">
                  <a v-if="item.picYn === 'Y'" class="button red sm"
                     @click.prevent="openFileUploader('02', item.cntrNo, idx, item.wavStsCd, item.rejectYn)"
                  >File</a>
                  <a v-else class="button sm" @click.prevent="openFileUploader('02', item.cntrNo, idx, item.wavStsCd, item.rejectYn)">File</a>
                </span>
                <span v-else>
                  <a v-if="item.picYn === 'Y'" class="button red sm">File</a>
                  <a v-else class="button sm">File</a>
                </span>
              </td>
            </tr>
            <tr>
              <td v-if="item.isChecked" colspan="11">
                <textarea :readOnly="item.editFlag === 'N'" v-model="item.reqCont" :id="'text-' + idx"></textarea>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody>
            <tr>
              <td colspan="11">{{ $t('msg.CSCT060G030.022') }}</td><!-- No Data. -->
            </tr>
          </tbody>
        </template>
      </table>
      <table class="tbl_row  mt10">
        <colgroup>
          <col width="14%"><col>
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.SETT070G010.027') }}</th> <!-- email -->
            <td><input type="text" placeholder="" v-model="send.reqCstEmlAddr" @keyup="fnEmailValidate()"></td>
          </tr>
        </tbody>
      </table>
      <div class="desc_list">
        <ul>
          <li>{{ $t('msg.SETT070G010.010') }}</li> <!-- email 수신처에 2인이상 입력시 콤마(,)를 넣은 후 이어 쓰시기 바랍니다. 최대 5명까지 등록 가능합니다. -->
        </ul>
      </div>
    </div>

    <div class="btn_area top">
      <div class="btn_right">
        <a class="button blue lg"
           @click.prevent="openPopup('DataUploadPop')"
        >{{ $t('msg.SETT070G010.011') }}</a>
        <a class="button blue lg" @click="saveData">{{ $t('msg.SETT070G010.012') }}</a>
        <a class="button gray lg" @click="cancel">{{ $t('msg.SETT070G010.028') }}</a>
      </div>
    </div>
    <br><br>

    <div id="layerPop">
      <win-layer-pop>
        <component
          :is="customComponent"
          v-if="customComponent"
          :parent-info="uploadParam"
          :is-show-pop="isShowPop"
          @close="closePopup"
          @selectFunc="selectFunc"
        />
      </win-layer-pop>
    </div>
    <div id="uploadPop">
      <win-layer-pop>
        <component
          :is="customComponent1"
          v-if="customComponent1"
          :send-info="send"
          :single-file-yn="true"
          @close="closePopup"
          @selectFunc="selectFunc"
        />
      </win-layer-pop>
    </div>
  </div>
</template>

<script>
import MrWaiverUploadPop from '@/pages/settle/popup/MrWaiverUploadPop'
import MrWaiverExcelPop from '@/pages/settle/popup/MrWaiverExcelPop'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import WinLayerPopWrap from '@/pages/trans/popup/WinLayerPopWrap'
import WrWaiverList from '@/api/rest/settle/wrWaiverList'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'

export default {
  name: 'MrWaiverList',
  components: {
    WinLayerPop,
    WinLayerPopWrap,
    MrWaiverUploadPop,
    MrWaiverExcelPop,
    EBreadcrumbs,
    'e-input-number': () => import('@/components/common/EInputNumber')
  },
  props: {

  },
  data: function () {
    return {
      chkFile: true,
      customComponent: null,
      customComponent1: null,
      isShowPop: false,
      uploadParam: [],
      fileInfo: [],
      fileTempList: [],
      saveParams: {},
      param: {
        blNo: '',
        clnAmt: '',
        curCd: '',
        reprAmt: ''
      },
      list: [],
      send: {
        blNo: '',
        mrRno: '',
        payAtPorYn: '',
        perBlMrAmt: '',
        perBlMrCur: '',
        perBlMrYn: '',
        reqCstEmlAddr: '',
        salUno: '',
        waiverInfo: [{
          clnAmt: '',
          cntrNo: '',
          curCd: '',
          reprAmt: '',
          reprPlcCd: '',
          reqCont: ''
        }]
      },
      popupParams: {},
      uploadParams: [],
      help_cleanChg: false,
      help_status: false,
      help_eor: false,
      help_picture: false,
      isSearched: false,

      // excel 업로드 에러
      excelErrorList: [],
      excelErrorErrYn: '',
      isError: false,
      isErrorMsg: '',

      // 도움말
      isShowCleaningChg: false,
      isShowStatus: false,
      isShowEOR: false,
      isShowPicture: false,
      isShowGuide: false,
      txtFilter: '',
      listTemp: [],
      prdYn: true,
      isCheckAll: false,
      checkCnt: 0
    }
  },
  computed: {
  },
  watch: {
    list: {
      deep: true,
      handler () {
        this.checkSingle()
      }
    }
  },
  created () {
    //window.functionOutsideVue(this)
  },
  mounted: function () {
    const $vm = this
  },
  methods: {
    searchData: function () {
      this.list = []
      this.listTemp = [] // JamSin CNTR filter
      this.txtFilter = '' // JamSin CNTR filter

      WrWaiverList.getWrWaiverList(this.param).then(response => {
        this.checkCnt = 0
        this.isCheckAll = false
        console.log('searchData >>> ', response)
        if (response.data.waiverList.length > 0) {
          this.isSearched = true
          this.list = this.parseSchSubs(response.data.waiverList)
          this.listTemp = this.parseSchSubs(response.data.waiverList) // JamSin CNTR filter
          // curCd 공백일때, dlyCurCd 로 대체. default값 생성
          this.list.forEach((item) => {
            if (item.curCd === '') {
              item.curCd = item.dlyCurCd
            }
          })

          this.send.reqCstEmlAddr = response.data.waiverList[0].reqCstEmlAddr
        }
      }).catch(err => {
        console.log(err)
      })
    },
    changeFilter () {
      // [[ JamSin CNTR filter
      //console.log('changeFilter>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.txtFilter)
      if (this.txtFilter === '') {
        this.list = this.listTemp
      } else {
        this.list = this.listTemp
        this.list = this.list.filter(vo => vo.cntrNo.indexOf(this.txtFilter) > -1)
      }
      // ]] JamSin CNTR filter
    },
    async saveData () {
      this.send.blNo = this.param.blNo
      this.send.salUno = this.list[0].salUno
      this.send.waiverInfo = this.list

      let isEmailOk = this.fnEmailValidate()
      let isFileOk = this.fnFileValidate()
      let errMsg = ''

      if (isEmailOk && isFileOk) {
        // if (this.isError) {
        //   errMsg = 'Excel 업로드 파일에 오류가 있습니다.'
        // } else {
          await WrWaiverList.saveMrSave(this.send)
          .then(response => {
            if (response.status === 200) {
              this.searchData()
              this.openAlert(this.$t('tem.CMAT015'))
            } else {
              console.log(response.status)
            }
          }).catch(error => {
            console.log(error)
          })
        // }
      }
    },
    fnConfirm (e) {
      WrWaiverList.mrcancel(this.send).then(response => {
        if (response.status === 200) {
          this.searchData()
          this.openAlert(this.$t('art.CMATK222'))
        } else {
          console.log(response.status)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    cancel: function () {
      let canYn = true

      this.list.forEach((item, idx) => {
        if (item.oldWavStsCd !== '00') {
            this.openAlert(this.$t('msg.SETT070G010.029')) //'Cancel 불가합니다.'
            canYn = false
        }
      })

      this.send.blNo = this.param.blNo

      if (canYn) {
        this.openConfirm(this.$t('msg.CMBK100_M1.039')) // 'Cancel하시겠습니까?'
      }
    },
    calculate: function (idx) {
        this.param.curCd = this.list[idx].curCd ? this.list[idx].curCd : ' '
        this.param.clnAmt = this.list[idx].clnAmt
        this.param.reprAmt = this.list[idx].reprAmt

        WrWaiverList.amtusd(this.param).then(response => {
          this.list[idx].totAmtUsd = response.data.amtUsd
        }).catch(err => {
          console.log(err)
        })
    },
    fnEmailValidate: function () {
      let isOk = true
      let chkVal = this.send.reqCstEmlAddr.split(',')

      if (chkVal.length > 5) {
        this.openAlert(this.$t('msg.ADD20220221.025')) // email주소는 최대 5명까지 등록 가능합니다.
        isOk = false
      }

      return isOk
    },
    fnFileValidate: function () {
      let isOk = true
      let eorInvalidCntrList = ''
      let picInvalidCntrList = ''
      let alertMsg = ''

      console.log('fnFileValidate >>>> ', this.list)
      this.list.some((item, idx) => {
        if (item.eorYn === 'N' && item.wavStsCd === '00' && (Number(item.reprAmt) + Number(item.clnAmt)) > 0 && item.mrYn === 'N') {
            isOk = false
            eorInvalidCntrList += (eorInvalidCntrList === '' ? '' : ',') + item.cntrNo
        }

        if (item.picYn === 'N' && item.wavStsCd === '00' && Number(item.totAmtUsd) >= 100) {
            isOk = false
            picInvalidCntrList += (picInvalidCntrList === '' ? '' : ',') + item.cntrNo
        }
      })

      if (eorInvalidCntrList !== '') {
        alertMsg = this.$t('msg.ADD20220221.025') + '\n(Container No. : ' + eorInvalidCntrList + ')' //전달받은 Estimate of Repair(수리견적서) 첨부부탁드립니다.
      }

      if (picInvalidCntrList !== '') {
        alertMsg += (alertMsg === '' ? '' : '<br><br>') + this.$t('msg.ADD20220221.026') + '\n(Container No. : ' + picInvalidCntrList + ')' //대미지 관련 사진파일 등록부탁드립니다.
      }

      if (alertMsg !== '') {
        this.openAlert(alertMsg)
      }

      return isOk
    },
    openPopup (compNm, cntrNo, idx, wavStsCd, rejectYn) {
      if (compNm === 'DataUploadPop' && this.list.length > 0) {
        this.send.blNo = this.param.blNo
        this.send.salUno = this.list[0].salUno
        this.send.waiverInfo = this.list
        this.isShowPop = true
        this.customComponent1 = MrWaiverExcelPop
        this.selectFunc = this.fileCheck
        // console.log('openPopup=====' + JSON.stringify(this.send))
      } else {
        this.openAlert('조회 후 업로드 가능합니다.')
        return
      }

      $('#uploadPop > .popup_dim').fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    openFileUploader (compNm, cntrNo, idx, wavStsCd, rejectYn) {
      this.popupParams.cntrNo = cntrNo
      this.popupParams.cnt = idx
      this.popupParams.stsCd = wavStsCd
      this.popupParams.blNo = this.param.blNo
      this.popupParams.fileKndCd = compNm
      this.uploadParam = []
      this.fileTempList = []
      WrWaiverList.getFileList(this.popupParams).then(response => {
        const resp = response.data.fileList
        console.log('response =>' + JSON.stringify(resp))

        if (resp.length > 0) {
          for (let i = 0; i < resp.length; i++) {
            this.uploadParam = [...this.uploadParam,
             {
              originalName: resp[i].originalName,
              size: resp[i].fileSz,
              // fileId: 'http://settle.ekmtc:9000/settle/mrwaiver/filedown?blNo=' + this.popupParams.blNo + '&cntrNo=' + this.popupParams.cntrNo + '&fileKndCd=' + this.popupParams.fileKndCd + '&fileId=' + resp[i].fileId
              fileId: resp[i].fileId
             }]
            this.fileTempList = [...this.uploadParam,
            {
              originalName: resp[i].originalName,
              size: resp[i].fileSz,
              // fileId: 'http://settle.ekmtc:9000/settle/mrwaiver/filedown?blNo=' + this.popupParams.blNo + '&cntrNo=' + this.popupParams.cntrNo + '&fileKndCd=' + this.popupParams.fileKndCd + '&fileId=' + resp[i].fileId
              fileId: resp[i].fileId
            }]
          }
        }

        console.log('uploadParam =>' + JSON.stringify(this.uploadParam))
        this.isShowPop = true
        this.customComponent = MrWaiverUploadPop
        this.selectFunc = this.setUploadInfo
        // console.log('selectFunc ===>' + JSON.stringify(this.selectFunc))
      }).catch(err => {
        console.log(err)
      })

      $('#layerPop > .popup_dim').fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    setUploadInfo (obj) {
      // console.log('setUploadInfo')
      // console.log('obj =>' + JSON.stringify(obj))
      // console.log('fileTempList =>' + JSON.stringify(this.fileTempList))
      this.fileInfo = []
       for (let i = 0; i < this.fileTempList.length; i++) {
         this.fileTempList[i].flag = 'D'
        //  console.log('uploadParam[i] D =>' + JSON.stringify(this.fileTempList[i]))
         for (let j = 0; j < obj.length; j++) {
           if (this.fileTempList[i].fileId === obj[j].fileId) {
             this.fileTempList[i].flag = 'U'
            //  console.log('uploadParam[i] U =>' + JSON.stringify(this.fileTempList[i]))
           }
         }
         if (this.fileTempList[i].flag === 'D') {
           var fid = this.fileTempList[i].fileId
           this.fileInfo.push({ fileId: fid.substr(fid.lastIndexOf('=') + 1), fileSt: 'D' })
         }
       }

       for (let k = 0; k < obj.length; k++) {
         if (obj[k].newFlag === 'Y') {
           this.fileInfo.push({ fileId: obj[k].fileId, fileSt: 'A' })
         }
       }

      this.saveParams = {
                          blNo: this.popupParams.blNo,
                          cntrNo: this.popupParams.cntrNo,
                          fileKndCd: this.popupParams.fileKndCd,
                          fileInfo: this.fileInfo
                        }
      // console.log('fileInfo =>' + JSON.stringify(this.fileInfo))

      if (this.fileInfo.length > 0) {
        WrWaiverList.setFileSave(this.saveParams).then(response => {
          if (this.popupParams.fileLen > 0) {
            if (this.popupParams.fileKndCd === '01') {
              this.list[this.popupParams.cnt].eorYn = 'Y'
            } else if (this.popupParams.fileKndCd === '02') {
              this.list[this.popupParams.cnt].picYn = 'Y'
            }
          } else {
            if (this.popupParams.fileKndCd === '01') {
              this.list[this.popupParams.cnt].eorYn = 'N'
            } else if (this.popupParams.fileKndCd === '02') {
              this.list[this.popupParams.cnt].picYn = 'N'
            }
          }

          //this.openAlert('파일저장')
        }).catch(err => {
          console.log(err)
        })
      }
    },
    closePopup (obj) {
      // console.log('obj====== ' + JSON.stringify(obj))
      this.isShowPop = false

      if (obj !== undefined) {
        this.popupParams.fileLen = obj.length
      } else {
        this.popupParams.fileLen = 0
      }

      $('#uploadPop > .popup_dim').fadeOut()
      $('#layerPop > .popup_dim').fadeOut()
      $('body,html').removeAttr('style')
    },
    closeFun () {
      this.$Simplert.close()
    },
    openAlert (msg, cssFlag) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.MYIN030T010.007'),
        useIcon: false,
        customClass: 'simple_alert',
        modifyCss: cssFlag,
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    openConfirm (msg, func) {
      let obj = {
        onClose: this.closeFun,
        onConfirm: this.fnConfirm,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: this.$t('msg.MYIN030T010.022'),
        customConfirmBtnText: this.$t('msg.MYIN030T010.007'),
        useConfirmBtn: true,
        customConfirmBtnClass: 'button blue lg',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    fileCheck (obj) {
      // console.log('fileCheck ======' + JSON.stringify(obj))
      this.excelErrorErrYn = obj.data.errMap.errYn

      let errorList = []
      let errorMsg = []
      let excelErrorList = []

      if (this.excelErrorErrYn === 'N') {
        this.list = obj.data.waiverList
        this.isError = false
      } else if (this.excelErrorErrYn === 'Y') {
        this.excelErrorList = obj.data.errMap.errList
        this.isError = true

        console.log('excelErrorList >>>> ', this.excelErrorList)
        this.excelErrorList.forEach((item, idx) => {
          let errTitleArr = []
          let errTypeArr = []
          let errCntrNo = ''

          if (item.errType !== '') {
            errTitleArr = item.errTitle.split(',')
            errTypeArr = item.errType.split(',')
            errCntrNo = item.errCntrNo

            errTitleArr.forEach((vo, idx) => {
              excelErrorList.push(
                {
                errTitle: vo,
                errType: errTypeArr[idx],
                errCntrNo: errCntrNo
                })
            })
          }
        })

        excelErrorList.forEach((item, idx) => {
          let errTitleArr = []
          let errTypeArr = []
          let errCntrNo = ''
          let excelErrorList = []

          if (item.errType !== '') {
            errTitleArr = item.errTitle.split(',')
            errTypeArr = item.errType.split(',')
            errCntrNo = item.errCntrNo

            errTitleArr.forEach((vo, idx) => {
              excelErrorList = {
                errTitle: vo,
                errType: errTypeArr[idx],
                errCntrNo: errCntrNo
              }
            })

            switch (item.errTitle) {
              case 'Repair Chg':
                if (item.errType === 'NUM_SCALE') {
                  errorMsg = this.$t('msg.SETT070G010.030') // 'Repair Chg는 소수점 3자리까지 입력가능합니다.'
                } else if (item.errType === 'NUM_FORMAT') {
                  errorMsg = this.$t('msg.SETT070G010.031') //'Repair Chg는 숫자만 입력가능합니다.'
                }
              break

              case 'Cleaning Chg':
                if (item.errType === 'NUM_SCALE') {
                  errorMsg = this.$t('msg.SETT070G010.032') // 'Cleaning Chg는 소수점 3자리까지 입력가능합니다.'
                } else if (item.errType === 'NUM_FORMAT') {
                  errorMsg = this.$t('msg.SETT070G010.033') // 'Cleaning Chg는 숫자만 입력가능합니다.'
                }
              break

              case 'Cur':
                if (item.errType === 'CUR_NONE') {
                  errorMsg = this.$t('msg.SETT070G010.034') // 'Cur는 필수값 입니다.'
                } else if (item.errType === 'CUR_ERR') {
                  errorMsg = this.$t('msg.SETT070G010.035') // '입력하신 Cur는 사용할수 없는 통화 입니다.'
                }
              break
            }

            errorList.push(
              {
                errType: item.errType,
                errTitle: item.errTitle,
                errCntrNo: item.errCntrNo,
                errMsg: errorMsg
              }
            )
          }
        })
        console.log('excelErrorList >>>> ', errorList)
      }

      if (errorList.length > 0) {
        let errorMsg = ''

        errorList.forEach((item, idx) => {
          errorMsg += '<span style=""; text-align:left;" >' + item.errMsg + '(Container No. : ' + item.errCntrNo + ')'
          errorMsg += '</span><br>'
        })

        this.openAlert(errorMsg, true)
      }
    },
    helpClick (tp) {
      if (tp === 'cleaningChg') {
        this.isShowCleaningChg = !this.isShowCleaningChg
      }

      if (tp === 'status') {
        this.isShowStatus = !this.isShowStatus
      }

      if (tp === 'EOR') {
        this.isShowEOR = !this.isShowEOR
      }

      if (tp === 'picture') {
        this.isShowPicture = !this.isShowPicture
      }

      if (tp === 'guide') {
        this.isShowGuide = !this.isShowGuide
      }
    },
    helpClose (tp) {
      $('#' + tp).hide()
    },
    parseSchSubs (cntrList) {
      for (let item of cntrList) {
        item.isChecked = false
      }
      return cntrList
    },
    checkSingle () {
      let checkCnt = 0
      for (let schSubs of this.list) {
        if (schSubs.isChecked) {
          checkCnt++
        }
      }

      if (checkCnt === this.list.length && checkCnt > 0) {
        this.isCheckAll = true
      } else {
        this.isCheckAll = false
      }
      this.checkCnt = checkCnt
    },
    checkAll () {
      this.isCheckAll = !this.isCheckAll
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].isChecked = this.isCheckAll
      }
      if (this.isCheckAll) {
        this.checkCnt = this.list.length
      } else {
        this.checkCnt = 0
      }
    }
  }
}
</script>
<style scoped>
.content_title_span {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
.bul_list_sm >li:before {
  content: none;
}
</style>
